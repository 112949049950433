import React, { useContext } from 'react'
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import "rc-slider/assets/index.css";
import 'rc-tooltip/assets/bootstrap.css';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/theme';


export default function SuccessModal () {
    const {signUpModal, setSignupModal} = useContext(UserContext)

    return (
        <>
           
            <div className="dashheaadbtncon">
                <Modal className='categorymodel' show={signUpModal?true:false} onHide={()=>setSignupModal(false)}>

                    <Modal.Body>
                        <div className='sussesbx'>
                            <div className='modlIcon'><iconify-icon icon="material-symbols:check"></iconify-icon></div>
                            <div className="categorycmodelhead">{signUpModal}</div>
                            <div className="loginsinphone ">
                                <div className="filterprolistbtncon justify-content-center">
                                    <div className="loginsignbtn filterreset"><button type='button' onClick={()=>setSignupModal(false)} className='theme-btn-1'>Ok</button></div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}
