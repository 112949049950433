

import React, { Suspense, useContext, useState, useEffect } from 'react';
import { Routes, Route, useLocation  } from 'react-router-dom';
import NotFound from '../components/notFound/notFound';
import { ToastContainer } from 'react-toastify';
import Loader from '../components/loader/loader';
import { UserContext } from '../context/theme';
import SiderMenu from './siderMenu';
import Header from './header';
import FooterUnAuth from '../unAuth/footerUnAuth';
import Redirect from './redirect';
import { socketConnectionURL } from '../services/api-url';



const Dashboard = React.lazy(() => import('../pages/dashboard/dashboard'));
const GetOTP = React.lazy(() => import('../components/login/signIn'));
const VerifyOTP = React.lazy(() => import('../components/login/VerifyOTP'));
const SignUp = React.lazy(() => import('../components/login/signUp'));
const Store = React.lazy(() => import('../pages/manageStore/store'));
const Invoice = React.lazy(() => import('../pages/invoice'));
const Categories = React.lazy(() => import('../pages/manageStore/categories'));
const Products = React.lazy(() => import('../pages/manageStore/products'));
const ProductCatalouge = React.lazy(() => import('../pages/manageStore/productCatalouge'));
const SingleUpload = React.lazy(() => import('../pages/manageStore/singleUpload'));
const BulkUpload = React.lazy(() => import('../pages/manageStore/bulkUpload'));
const ProductList = React.lazy(() => import('../pages/manageStore/productList'));
const ProductCatalougeList = React.lazy(() => import('../pages/manageStore/productCatalougeList'));
const Profile = React.lazy(() => import('../pages/profile/profile'));
const Help = React.lazy(() => import('../pages/helpdesk/help'));
const HelpTicketView = React.lazy(() => import('../pages/helpdesk/helpTicketView'));
const RaiseTicket = React.lazy(() => import('../pages/helpdesk/raiseTicket'));
const StoreProductCatalogue = React.lazy(() => import('../pages/dashboard/storeProductCatalogue'));
const Inquiry = React.lazy(() => import('../pages/manageStore/inquiry'));
const Chart = React.lazy(() => import('../pages/reports/chart'));
const UserList = React.lazy(() => import('../pages/userRole/userList'));
const CreateUser = React.lazy(() => import('../pages/userRole/createUser'));
const Notification = React.lazy(() => import('../pages/notification'));
const Faq = React.lazy(() => import('../pages/faq'));
const AboutUs = React.lazy(() => import('../pages/aboutUs'));
const StoreProductView = React.lazy(() => import('../pages/dashboard/storeProductView'));
const StoreProductList = React.lazy(() => import('../pages/dashboard/storeProductList'));
const ViewStore = React.lazy(() => import('../pages/dashboard/viewStore'));
const ReviewRating = React.lazy(() => import('../pages/reviewRating'));
const ProfileCustomer = React.lazy(() => import('../pages/profileCustomer/profileCustomer'));
const ActiveCustomer = React.lazy(() => import('../pages/manageCustomer/activeCustomer'));
const PendingCustomer = React.lazy(() => import('../pages/manageCustomer/pendingCustomer'));
const CustomerRequest = React.lazy(() => import('../pages/manageCustomer/customerRequest'));
const InviteCustomer = React.lazy(() => import('../pages/manageCustomer/inviteCustomer'));
const QuickTutorial = React.lazy(() => import('../pages/dashboard/quickTutorial'));
const ActiveSubcription = React.lazy(() => import('../pages/dashboard/activeSubcription'));
const PrivacyPolicy = React.lazy(() => import('../pages/privacyPolicy'));
const TermCondition = React.lazy(() => import('../pages/termCondition'));
const CancellationAndRefund = React.lazy(() => import('../pages/cancellationAndRefund'));
const ContactUs = React.lazy(() => import('../pages/contactUs'));
import io from "socket.io-client";

const DashLayout = ({ Component }) => {
  const context = useContext(UserContext);
  const {auth, setSocket, userInfo} = context
  const authLocal = localStorage.getItem("token");
    useEffect(() => {
        if(auth && authLocal != undefined){
            const connection = io(`${socketConnectionURL}?type=customer&token=${authLocal}`, { transports: ["websocket", "polling"] });
            // console.log(connection)
            setSocket(connection || {})
            
            connection.on("connect", () => {
                // setIsConnected(true);
                // socket.emit("joinRoom", { ticketNumber: "" });
                // console.log(socket.id);
            });

            connection.on("disconnect", () => {
                // setIsConnected(false);
            });
            return () => {
                connection.off("connect");
                connection.off("disconnect");
            };
        }

        
    }, [auth, authLocal]);
  return (
    <>
      <div className={`page-wrapper 
      ${context.Theme} 
      ${context.ThemeColor} 
      ${context.MiniSidebar ? 'mini-sidebar' : ''}      
    `}>
        <Header />
        <div className="all-content-wrapper">
          <SiderMenu />
          <div className="mainContent">
            <div className="container-fluid">
              <Loader />
              <Suspense fallback={'loading'}>
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/signin" element={<GetOTP />} />
                  <Route path="/verifyotp" element={<VerifyOTP />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/store" element={<Store />} />
                  <Route path="/categories" element={<Categories />} />
                  <Route path="/product" element={<Products />} />
                  <Route path="/product/catalouge" element={<ProductCatalouge />} />
                  <Route path="/bulkUpload" element={<BulkUpload />} />
                  <Route path="/singleUpload" element={<SingleUpload />} />
                  <Route path="/singleUpload/:productId" element={<SingleUpload />} />
                  <Route path="/product/list" element={<ProductList />} />
                  <Route path="/product/catalogue/list" element={<ProductCatalougeList />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/help" element={<Help />} />
                  <Route path="/helpTicketView/:ticketId" element={<HelpTicketView />} />
                  <Route path="/raiseTicket" element={<RaiseTicket />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/terms-conditions" element={<TermCondition />} />
                  <Route path="/cancellation-and-refund" element={<CancellationAndRefund />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/active-subcription" element={<ActiveSubcription />} />
                  <Route path="/quick-tutorial" element={<QuickTutorial />} />
                  <Route path="/invite-customer" element={<InviteCustomer />} />
                  <Route path="/customer-request" element={<CustomerRequest />} />
                  <Route path="/pending-customer" element={<PendingCustomer />} />
                  <Route path="/active-customer" element={<ActiveCustomer />} />
                  <Route path="/customers/view/:id" element={<ProfileCustomer />} />
                  <Route path="/review-rating/:type/:ratingId" element={<ReviewRating />} />
                  <Route path="/store/view/:id" element={<ViewStore />} />
                  <Route path="/store/product/list" element={<StoreProductList />} />
                  <Route path="/store/product/view/:id" element={<StoreProductView />} />
                  <Route path="/create-user" element={<CreateUser />} />
                  <Route path="/edit-user/:userId" element={<CreateUser />} />
                  <Route path="/user-list" element={<UserList />} />
                  <Route path="/about-us" element={<AboutUs />} />
                  <Route path="/faq" element={<Faq />} />
                  <Route path="/notification" element={<Notification />} />
                  <Route path="/chart" element={<Chart />} />
                  <Route path="/product/inquiry" element={<Inquiry />} />
                  <Route path="/store/product/catalogue" element={<StoreProductCatalogue />} />
                  <Route path="/invoice" element={<Invoice />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Suspense>
            </div>
          </div>
        </div>
        <FooterUnAuth />
      </div>
    </>
  );
}
const MainLayout = ({ Component }) => {
  const context = useContext(UserContext);
  return (
    <>
      <div className={`page-wrapper ${context.Theme} ${context.ThemeColor} pageLogin`}>
        <div className="login-wrapper">
            {/* <div className="all-content-wrapper"> */}
            {/* <TopNavbar/>  */}
            <Loader />
            <Header />
            <SiderMenu />
          <div className="saledeskWRP">
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/about-us" element={<AboutUs />} />
              
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/terms-conditions" element={<TermCondition />} />
              <Route path="/cancellation-and-refund" element={<CancellationAndRefund />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/"  element={<Redirect to="/dashboard" />} />
              <Route path="/verifyOTP" element={<Redirect to="/dashboard" />} />
              <Route path="/signUp" element={<Redirect to="/dashboard" />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
            <FooterUnAuth />
        </div>
      </div>
    </>
  );
};

function Layout() {
  const [layoutType, setLayoutType] = useState(false);
  const context = useContext(UserContext);
  const { pathname } = useLocation();
  const publicPath = ["/", "/about-us", "/contact-us", "/terms-conditions","/faq", "/privacy-policy","/verifyOTP","/signup"];
  useEffect(() => {
    let check = publicPath.includes(pathname);
    context.setGlobalLayout(check)
    setLayoutType(check);
  }, [pathname]);
  return (
    <>
      <ToastContainer />
      <Suspense fallback={<Loader />}>{layoutType ? <MainLayout /> : <DashLayout />}</Suspense>
    </>
  );
}
export default Layout;
