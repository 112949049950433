import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authAxios from "../services/authAxios";
// import Layout from "../layout/layout";
// import Login from "../pages/login";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate()
  const [auth, setAuth] = useState(false);
  const [globalLayout, setGlobalLayout] = useState(true);
  const [signUpModal, setSignupModal] = useState(false);
  const [Theme, setTheme] = useState("theme-1");
  const [ThemeColor, setThemeColor] = useState("");
  const [MiniSidebar, setMiniSidebar] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [storeData, setStoreData] = useState({});
  const [storeStatus, setStoreStatus] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState({});
  const [counts, setCounts] = useState({});
  const [routeHistory, setRoutHistory] = useState([]);
  const [employData, setEmpolyData] = useState({});
  const [socket, setSocket] = useState({});
  const [sideMenuActive, setSideMenuActive] = useState(false)
  const [notificationData, setNotificationData] = useState([])
  const reset = () => {
    localStorage.clear();
    setGlobalLayout(true)
    setSocket({})
    setSignupModal(false)
    setUserInfo({});
    setEmpolyData({});
    setRoutHistory([]);
    setNotificationData([]);
    setBreadcrumbs({});
    setStoreStatus(null);
    setStoreData(null);
    setCounts({});
    setAuth(false);
    navigate("/");
  };

  function getProfile() {
    // common.loader(true);
    authAxios({
      method: "GET",
      url: `/v/profile`,
    })
      .then((res) => {
        let resData = res?.data?.data || {};
        setUserInfo(resData);
      })
      .catch((error) => {
        console.log(error);
      });
    // common.loader(false);
  }
  function getStore() {
    // common.loader(true);
    authAxios({
      method: "GET",
      url: `/v/manageStore`,
    })
      .then((res) => {
        let resData = res?.data?.data || {
          brands:[{
            "brandName": "",
            "brandLogo": ""
        }]
        };
        setStoreStatus(resData?.address ? true : false)
        setStoreData(resData);
      })
      .catch((error) => {
        console.log(error);
      });
    // common.loader(false);
  }
  function getEmployee(id) {
    // common.loader(true);
    let postId = id || localStorage.getItem('employeeId')
    if(postId && postId !== 'null'){
        authAxios({
            method: "GET",
            url: `/v/user/employeeDetails/${postId}`,
        }).then((res) => {
            let resData = res?.data?.data || {};
            setEmpolyData(resData);
        }).catch((error) => {
            console.log(error)
        });
    }
// common.loader(false);
}
  const  getStoreCounts = () => {
    // common.loader(true);
    authAxios({
      method: "GET",
      url: `/v/counts`,
    })
      .then((res) => {
        let resData = res?.data?.data || {};
        setCounts(resData)
      })
      .catch((error) => {
        console.log(error);
      });
    // common.loader(false);
  }
  function refreshtoken() {
    authAxios({
      method: "GET",
      url: `/v/user/tokenRefresh`,
    })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    let intrVal;
    if (auth) {
      getProfile();
      getStore()
      getStoreCounts()
      getEmployee();
      intrVal = setInterval(() => {
        refreshtoken();
      }, 60 * 1000);
    }
    return () => clearInterval(intrVal);
  }, [auth]);

  useEffect(() => {
    let authLocal = localStorage.getItem("token");
    setAuth(authLocal);
  }, []);
  return (
    <UserContext.Provider
      value={{
        ThemeColor,
        Theme,
        auth,
        MiniSidebar,
        reset,
        getStoreCounts,
        counts, setCounts,
        getEmployee,
        sideMenuActive, setSideMenuActive,
        employData, setEmpolyData,
        globalLayout, setGlobalLayout,
        routeHistory, setRoutHistory,
        signUpModal, setSignupModal,
        notificationData, setNotificationData,
        socket, setSocket,
        getProfile,
        storeData, 
        setStoreData,
        storeStatus, 
        setStoreStatus,
        getStore,
        setThemeColor,
        setTheme,
        setAuth,
        setMiniSidebar,
        userInfo,
        setUserInfo,
        breadcrumbs,
        setBreadcrumbs,        
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
