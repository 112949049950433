let ROOT_URL = "";
let IMAGE_URL = "";
let socketConnectionURL = "";
let REACT_APP_REDIRECT_URL = "";
// console.log(process.env.REACT_APP_SERVER);
if (process.env.REACT_APP_SERVER?.trim() == "production") {
  ROOT_URL = "https://api.saledesk.io/api/v1";
  socketConnectionURL = "https://api.saledesk.io"
  REACT_APP_REDIRECT_URL = "https://saledesk.io"
  IMAGE_URL = "";
} else {
  ROOT_URL = "https://api.saledesk.io/api/v1";
  socketConnectionURL= "https://api.saledesk.io"
  REACT_APP_REDIRECT_URL = "https://saledesk.io"
  IMAGE_URL = "";
}
export { IMAGE_URL, socketConnectionURL, REACT_APP_REDIRECT_URL };
export default ROOT_URL;

