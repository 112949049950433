// import { Collapse } from 'bootstrap';
import React, { useContext, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DropDownMenu, { Dropdown, Menu } from "../components/common/sidebarMenu";
import { UserContext } from "../context/theme";

function SiderMenu() {
    const history = useLocation()
    const { reset, counts, employData, storeStatus, sideMenuActive, setSideMenuActive } = useContext(UserContext)
    useEffect(()=>{
        setSideMenuActive(false)
        document.body.className = document.body.className.replace("overflowHidn", "");
    },[history])
    const toggleSidebar = ()=>{
        setSideMenuActive(!sideMenuActive)
        if(sideMenuActive){
            document.body.className = ""
        }else{
            document.body.className = "overflowHidn"
        }        
    }
    const navigate = useNavigate();
    const hanleStore = () => {
        toast.warning("Please create store before adding product");
        navigate("/store");
    };

    // useEffect(() => {
    //     var myCollapse = document.getElementById('collapseTarget')
    //     var bsCollapse = new Collapse(myCollapse, {toggle: false})
    //     bsCollapse.show()
    // })
    return (
        <>
            <div className={`left-sidebar-wrapper ${sideMenuActive ? "active" : ""}`}>
                <div className={`leftSideBrBackGrnd ${sideMenuActive ? "active" : ""}`} onClick={() => toggleSidebar()}></div>
                <nav className="sidebar">
                    <div className="left-nav-menu">
                        <DropDownMenu>
                            <Menu path="/dashboard" title="Dashboard" icon="radix-icons:dashboard" iconifyIcon="true" />
                            <Dropdown title="Manage Store" icon="clarity:store-line" iconifyIcon="true">
                                <Menu path="/store" title="Store" iconifyIcon="true" />
                                {((employData?.role === "sm" && employData?.role !== "rm") || employData?.role === "dr" || employData?.role === undefined) &&
                                    (storeStatus === true ? (
                                        <Menu path="/categories" title="Categories" iconifyIcon="true" />
                                    ) : (
                                        <li>
                                            <a className="collapse has_menu " onClick={() => hanleStore()}>
                                                <span className="menu_txt">Categories </span>
                                            </a>
                                        </li>
                                    ))}
                                {((employData?.role === "sm" && employData?.role !== "rm") || employData?.role === "dr" || employData?.role === undefined) &&
                                    (storeStatus === true ? (
                                        <Menu path="/product" title={`Product (${counts?.productCount || 0})`} iconifyIcon="true" />
                                    ) : (
                                        <li>
                                            <a className="collapse has_menu " onClick={() => hanleStore()}>
                                                <span className="menu_txt">Product ({counts?.productCount || 0})</span>
                                            </a>
                                        </li>
                                    ))}
                                {((employData?.role === "sm" && employData?.role !== "rm") || employData?.role === "dr" || employData?.role === undefined) &&
                                    (storeStatus === true ? (
                                        <Menu path="/product/catalouge" title={`Product Catalouge (${counts?.productCatalogueCount || 0})`} iconifyIcon="true" />
                                    ) : (
                                        <li>
                                            <a className="collapse has_menu " onClick={() => hanleStore()}>
                                                <span className="menu_txt">Product Catalouge ({counts?.productCatalogueCount || 0}) </span>
                                            </a>
                                        </li>
                                    ))}
                                {((employData?.role !== "sm" && employData?.role === "rm") || employData?.role === "dr" || employData?.role === undefined) && <Menu path="/product/inquiry" title={`Product Inquiry (${counts?.productInquiryCount || 0})`} iconifyIcon="true" />}
                            </Dropdown>
                            {((employData?.role !== "sm" && employData?.role === "rm") || employData?.role === "dr" || employData?.role === undefined) && (
                                <Dropdown title="Manage Customers" icon="material-symbols:manage-accounts-outline-rounded" iconifyIcon="true">
                                    <Menu path="/invite-customer" title={`Invite Customer`} iconifyIcon="true" />
                                    <Menu path="/pending-customer" title={`Pending Customers (${counts?.invitedCustomersCount || 0})`} iconifyIcon="true" />
                                    <Menu path="/active-customer" title={`Active Customers (${counts?.activeCustomersCount || 0})`} iconifyIcon="true" />
                                    <Menu path="/customer-request" title={`Customer Requests (${counts?.customersRequestsCount || 0})`} iconifyIcon="true" />
                                </Dropdown>
                            )}
                            {((employData?.role !== "sm" && employData?.role !== "rm") || employData?.role === "dr" || employData?.role === undefined) && <Menu path="/user-list" title="Manage User & Roles" icon="material-symbols:manage-accounts-outline-rounded" iconifyIcon="true" />}
                            {((employData?.role !== "sm" && employData?.role === "rm") || employData?.role === "dr" || employData?.role === undefined) && <Menu path="/chart" title="Reports & Analytics" icon="ri:bar-chart-2-line" iconifyIcon="true" />}
                            {((employData?.role !== "sm" && employData?.role === "rm") || employData?.role === "dr" || employData?.role === undefined) && <Menu path="/help" title="Help" icon="carbon:help" iconifyIcon="true" />}
                            {((employData?.role !== "sm" && employData?.role !== "rm") || employData?.role === "dr" || employData?.role === undefined) && (
                                <Menu path="/faq" title="FAQ" icon="wpf:faq" iconifyIcon="true" />
                            )}
                            {((employData?.role !== "sm" && employData?.role !== "rm") || employData?.role === "dr" || employData?.role === undefined) && (
                                <Menu path="/profile" title="Profile" icon="gg:profile" iconifyIcon="true" />
                            )}
                            {((employData?.role !== "sm" && employData?.role !== "rm") || employData?.role === "dr" || employData?.role === undefined) && (
                                <Menu path="/active-subcription" title="My Subscription " icon="fa:diamond" iconifyIcon="true" />
                            )}
                            <li>
                                <a className="collapse has_menu " onClick={() => reset()}>
                                    <span>
                                        <iconify-icon icon="mdi:logout"></iconify-icon>
                                    </span>
                                    <span className="menu_txt">Sign Out </span>
                                </a>
                            </li>
                        </DropDownMenu>
                    </div>
                </nav>
            </div>
        </>
    );
}
export default SiderMenu;
