// import logo from './logo.svg';

import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import React, { useEffect, useState } from 'react';
import { UserContext, UserProvider } from "./context/theme";
import Layout from "./layout/layout";
import 'react-toastify/dist/ReactToastify.css';
import UnAuth from "./unAuth/UnAuth";
import ScrollToTop from "./layout/scrollToTop";

function App() {

  /*   const [auth, setAuth] = useState(false);
    const [Theme, setTheme] = useState("theme-1");
    const [ThemeColor, setThemeColor] = useState("");
    const [MiniSidebar, setMiniSidebar] = useState("");
  
    
    useEffect(()=>{
      let thm = localStorage.getItem('theme') || "theme-1";
      let thmClr = localStorage.getItem('themeColor');
      let authLocal = localStorage.getItem('token');
      setTheme(thm);
      setThemeColor(thmClr);
      setAuth(authLocal);
    },[]) */


  return (
    <>
      <ScrollToTop>
        <UserProvider>
          <UserContext.Consumer>
            {({ auth }) => (
              auth ? <Layout /> : <UnAuth />
            )}
          </UserContext.Consumer>
        </UserProvider>
        </ScrollToTop>
      </>
  );
}

export default App;
