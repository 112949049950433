import React from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from '../components/loader/loader'
import { UserContext } from '../context/theme'

const Redirect = ({to}) => {
  const params = new URLSearchParams(window.location.search);
  let redirect = params.get("redirect");
  let urlString = ''
  if(redirect){
      urlString = '?redirect='+redirect
  }
    // const {auth} = useContext(UserContext)
    const navigate = useNavigate()
    useEffect(()=>{
        if(to){
            navigate(to+urlString)
        }
    })
  return (
    <>
        <Loader />
    </>
  )
}

export default Redirect